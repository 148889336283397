import { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Buffer } from "buffer";

import "./detallescnu.css";

const Detailsonu = () => {
  const { id } = useParams();
  const [cnuevos, setCnuevo] = useState(null);
  const { state = { success: false } } = useLocation();

  

  const navigate = useNavigate();

  function handleLogout(event) {
    event.preventDefault();

    localStorage.removeItem("state");

    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(`https://inforlan-server.herokuapp.com/api/cnu`)
      .then((x) => x.json())
      .then((y) => {
        const data = y.data;
        const cnuevo = data.find((b) => b.id === Number(id));
        console.log(id, data);
        setCnuevo(cnuevo);
      });
  }, [id]);

  
  // const element = cnuevos.find(e => e.id === cnuevos.id);
  // const cnuevosString = JSON.stringify(cnuevos)

  return (
    <div>
      <div className="navbar">
        <ul>
          <li className="item5">
            {" "}
            <Link to={-1}>Atrás</Link>
          </li>
          <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state})}>
              Inicio
            </p>
          </li>
          {state === null ||
          !state.success ||
          state.success === false ||
          state.user === null ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              <li className="item1">
                <p onClick={() => navigate("/perfil", { state })}>
                  Perfil de {state.user.usuario}
                </p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )}
        </ul>
      </div>
      <br></br><br></br><br></br>
          <header className="seccion">
        <p className="lin">
          Detalles del producto
        </p>
      </header>
      <br></br><br></br>
      {cnuevos && (
        <article className="seccion" key={cnuevos.id}>
          {console.log(cnuevos)}
          
          <br></br>
          <br></br>
          <img
            className="imagennp"
            src={`data:image/jpeg;base64,${Buffer.from(
              cnuevos.data,
              "binary"
            ).toString()}`}
            alt={`Imagen del ordenador ${cnuevos.nombre}`}
          />

          <h3 className="nombrenp">{cnuevos.nombre}</h3>
          <h2 className="precionp">{cnuevos.precio} €</h2>
          <h4 className="descripcionnp">{cnuevos.descripcion}</h4>
          <br></br>
          
          {state === null ||
          !state.success ||
          state.success === false ||
          state.user === null ? (
            <div>
              <ul>
                <li className='na'>
                  <p>Inicia sesión para comprar</p>
                </li>
              </ul>
              <br></br>
            </div>
          ) : (
            <div>
              <ul>
                <li className="item6">
                  <p
                    onClick={() =>
                      navigate(`/pago/${cnuevos.id}`, {state, state2: cnuevos})
                    }
                  >
                    Comprar
                  </p>
                </li>
              </ul>
              <br></br>
            </div>
            
          )}
          <br></br>
          <br></br>
        </article>
      )}
      <br></br>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
        <p>Copyright &copy; INFORLAN. All rights reserved</p>
        <p>
          Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels,
          Barcelona
        </p>
        <p>936 36 52 77 • 638 28 85 48</p>
        <p>jlgurru@inforlan.es</p>
        <br></br>
        <p
          className="linkpoli"
          onClick={() => navigate("/privacidad", { state })}
        >
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", { state })}>
          Política de Cookies
        </p>
      </footer>
    </div>
  );
};


export default Detailsonu;
