import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./contacto.css";

const Contacto = () => {

    const { state = { success: false, user: false } } = useLocation();
    const navigate = useNavigate();

    function handleLogout(event) {
      event.preventDefault();
      // Borra la información de sesión del usuario
      localStorage.removeItem("state");
      // Redirige a la página de inicio
      navigate("/");
    }

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div>
        <div className='navbar'>
        <ul>
        <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>Inicio</p>
            </li>
            {state === null || !state.success || state.success === false ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              
              <li className="item1">
                <p onClick={() => navigate("/perfil", {state})}>Perfil de {state.user.usuario}</p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )} 
        </ul>
        </div>
        <br></br>
      <br></br>
      <br></br><br></br>
      <header className="seccion">
        <p className="lin">
          Contacto
        </p>
      </header>
      <br></br>
      <br></br>
      <br></br>
      <article className="seccion">
      <br></br><br></br><br></br>
      <h5 className = 'tituloscontacto'>📧 Email:</h5>
        <a className="datoscontacto" href="mailto:jlgurru@inforlan.es">jlgurru@inforlan.es</a>
        <br></br><br></br><br></br><br></br><br></br>
        <h5 className = 'tituloscontacto'>☎️ Telefono:</h5>
        <a className="datoscontacto" href="tel:638288548">936 36 52 77 • 638 28 85 48</a>
        <br></br><br></br><br></br><br></br><br></br>
        <h5 className = 'tituloscontacto'>📍 Dirección:</h5>
        <a className="datoscontacto" href="https://www.google.com/maps/place/Inforlan/@41.2785704,1.972184,17z/data=!3m1!4b1!4m5!3m4!1s0x12a482ba25603e8f:0xd6cc17ed5c7a070f!8m2!3d41.278574!4d1.976663"> Carrer de la Llibertat, 13, Entresuelo, B-2,<br></br>08860 Castelldefels, Barcelona</a>
        <br></br><br></br><br></br><br></br><br></br>
        <h5 className = 'tituloscontacto'>🗓️ Horario:</h5>
        <p className="datoscontacto">De Lunes a Viernes: <br></br>de 9:30 a 13:30h y de 17:00 a 22:00h
        <br></br>
          <br></br>Sábados:<br></br> de 10:00 a 13:00h y de 17:00 a 20:00h
</p> 
        
        <br></br><br></br><br></br>
      </article>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
      <p>
          Copyright &copy; INFORLAN. All rights reserved
        </p>
        <p>
        Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels, Barcelona
        </p>
        <p>
        936 36 52 77 • 638 28 85 48

        </p>
        <p>
        jlgurru@inforlan.es

        </p>
        <br></br>
        <p className="linkpoli" onClick={() => navigate("/privacidad", {state})}>
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", {state})}>
          Política de Cookies
        </p>
        
      </footer>
    </div>
  );
};

export default Contacto;
