
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';

function CompoNuevosi() {
  
  const  {state = { success: false }}  = useLocation();

  const navigate = useNavigate();
  
  function handleLogout(event) {
    event.preventDefault();
    // Borra la información de sesión del usuario
    localStorage.removeItem("state");
    // Redirige a la página de inicio
    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [image, setImage] = useState(null);
  const [nombre, setNombre] = useState('');
  const [precio, setPrecio] = useState('');
  const [descripcion, setDescripcion] = useState('');

  const handleChange = (event) => {
    console.log(event.target.files)
    setImage(event.target.files[0]);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('image', image);


//     axios.post('http://localhost:3001/api/onui', formData, precioData).then(() => {
//   navigate("/ordenanuevos");
//   console.log('Image saved to database');
// }).catch((error) => {
//   console.error(error);
// });
//   }

  axios.post('https://inforlan-server.herokuapp.com/api/cnui', formData, { 
    params: {
    Name: nombre,
    Price: precio,
    Desc: descripcion
    }
}).then(() => {
  navigate("/componuevos", { state });
  console.log('Image saved to database');
}).catch((error) => {
  console.error(error);
});
  }
  
  return (
    <div>
    <div className="navbar">
        <ul>
        <li className="item5"> <Link to={-1}>Atrás</Link></li>
        <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
          {state === null || !state.success || state.success === false ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              
              <li className="item1">
                <p onClick={() => navigate("/perfil", {state})}>Perfil de {state.user.usuario}</p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )} 
        </ul>
      </div>
      <br />
      <br /> <br />
      <br /> <br />
      

      <header className="seccion">
        <p className="lin">
          Sube un componente nuevo
          
        </p>
      </header>
      <br />
      <br />
      <form onSubmit={handleSubmit}>
      <label>
      <br/>
        *Nombre:
        <br/>
        <input type="text" value={nombre} onChange={e => setNombre(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Precio:
        <br/>
        <input type="number" value={precio} onChange={e => setPrecio(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Descripcion:
        <br/>
        <textarea type="text" value={descripcion} onChange={e => setDescripcion(e.target.value)} />
      </label>
      <br/>
      <input type="file" onChange={handleChange} />
      <button type="submit">Subir</button>
    </form>
    </div>
  );
}

export default CompoNuevosi;