import { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Buffer } from "buffer";


const Detailsosm = () => {
    const { id } = useParams();
    const [osmano, setOsmano] = useState(null);
    const {
      state = {
        success: false,
  
      },
    } = useLocation();
  
    const navigate = useNavigate();
  
    function handleLogout(event) {
      event.preventDefault();
  
      localStorage.removeItem("state");
  
      navigate("/");
    }
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
      fetch(`https://inforlan-server.herokuapp.com/api/osm`)
        .then((x) => x.json())
        .then((y) => {
          const data = y.data;
          const osmano = data.find((b) => b.id === Number(id));
          console.log(id, data);
          setOsmano(osmano);
        });
    }, [id]);

    return (
      <div>
        <div className="navbar">
        <ul>
          <li className="item5">
            {" "}
            <Link to={-1}>Atrás</Link>
          </li>
          <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
          {state === null || !state.success || state.success === false || state.user === null ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              <li className="item1">
                <p onClick={() => navigate("/perfil", { state })}>
                  Perfil de {state.user.usuario}
                </p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )}
          </ul>
          </div>
          <br></br><br></br><br></br>
          <header className="seccion">
        <p className="lin">
          Detalles del producto
        </p>
      </header>
      <br></br><br></br>
{osmano &&(
  <article className="seccion" key={osmano.id}>
              {console.log(osmano.data)}
              <br></br><br></br>
              <img
                className="imagennp"
                src={`data:image/jpeg;base64,${Buffer.from(
                  osmano.data,
                  "binary"
                ).toString()}`}
                alt={`Imagen del ordenador ${osmano.nombre}`}
              />
              
              <h3 className="nombrenp">{osmano.nombre}</h3>
              <h2 className="precionp">{osmano.precio} €</h2>
              <h4 className="descripcionnp">{osmano.descripcion}</h4>
              <br></br>{state === null ||
          !state.success ||
          state.success === false ||
          state.user === null ? (
            <div>
              <ul>
                <li className='na'>
                  <p>Inicia sesión para comprar</p>
                </li>
              </ul>
              <br></br>
            </div>
          ) : (
            <div>
              <ul>
                <li className="item6">
                  <p
                    onClick={() =>
                      navigate(`/pago/${osmano.id}`, { state1: state, state2: osmano})
                    }
                  >
                    Comprar
                  </p>
                </li>
              </ul>
              <br></br>
            </div>
            
          )}
          <br></br>
          <br></br>
            </article>
)}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
        <p>Copyright &copy; INFORLAN. All rights reserved</p>
        <p>
          Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels,
          Barcelona
        </p>
        <p>936 36 52 77 • 638 28 85 48</p>
        <p>jlgurru@inforlan.es</p>
        <br></br>
        <p
          className="linkpoli"
          onClick={() => navigate("/privacidad", { state })}
        >
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", { state })}>
          Política de Cookies
        </p>
      </footer>
      </div>
    );
}
export default Detailsosm;