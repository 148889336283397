import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./IndexPage.css";

const Secciones = require.context("../../../public/img", true);

function IndexPage() {
  const  {state = { success: false }}  = useLocation();
  const navigate = useNavigate();
  
  function handleLogout(event) {
    event.preventDefault();
    localStorage.removeItem("state");
    navigate("/");
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="navbar">
        <ul>
          {state === null || !state.success || state.success === false ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              
              <li className="item1">
                <p onClick={() => navigate("/perfil", {state})}>Perfil de {state.user.usuario}</p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )} 
        </ul>
      </div>
      <br />
      <br /> <br />
      <br /> <br />
      

      <header className="fondo">
      <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <div className="logo">
          <br></br>
          <img src={Secciones("./logotipo.png")} width="60%" alt="logotipo" />
          <br></br>
          <h1 className="desde">
          <strong>Desde 1995</strong>
          
        </h1>
        <p className="construccion blinking">🚧 WEB EN CONSTRUCCIÓN 🚧</p>
        <br></br>
        </div>
        <br></br><br></br><br></br><br></br><br></br>
      </header>
      <br />
      <br />
      <br />
      <br />
      <br /><br />
      <br />
      <br />
      <br />
      <br />
      <div className="seccion">
        <br />
        <p onClick={() => navigate("/reparaciones", {state})} className="lin link">
          Reparaciones
        </p>
        <br></br>
        <div>
          <p onClick={() => navigate("/reparaciones", {state})}>
            <img
              className="opaci"
              src={Secciones("./rep1.jpeg")}
              alt="imagen de reparaciones"
            />
          </p>
        </div>
        <br />
      </div>
      <br />
      <br />
      <div className="seccion">
        <br />
        <p onClick={() => navigate("/tiendaprev", {state})} className="lin link">
          Productos
        </p>
        <br></br>
        <div>
          <p onClick={() => navigate("/tiendaprev", {state})}>
            <img
              className="opaci"
              src={Secciones("./tienda2.jpeg")}
              alt="imagen de tienda"
            />
          </p>
        </div>
        <br />
      </div>
      <br />
      <br />
      <div className="seccion">
        <br />
        <p onClick={() => navigate("/oportunidades", {state})} className="lin link">
          Ofertas
        </p>
        <br></br>
        <div>
          <p onClick={() => navigate("/oportunidades", {state}, {replace: true})}>
            <img
              className="opaci"
              src={Secciones("./oportunidades.jpg")}
              alt="imagen de oportunidades"
            />
          </p>
        </div>
        <br />
      </div>
      <br />
      <br />
      <div className="seccion">
        <br />
        <p onClick={() => navigate("/contacto", {state})} className="lin link">
          Contacto y horarios
        </p>
        <br></br>
        <div>
          <p onClick={() => navigate("/contacto", {state}, {replace: true})}>
            <img
              className="opaci"
              src={Secciones("./contacto2.jpg")}
              alt="imagen de contacto"
            />
          </p>
        </div>
        <br />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <footer className="section-footer">
      <p>
          Copyright &copy; INFORLAN. All rights reserved
        </p>
        <p>
        Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels, Barcelona
        </p>
        <p>
        936 36 52 77 • 638 28 85 48

        </p>
        <p>
        jlgurru@inforlan.es

        </p>
        <br></br>
        <p className="linkpoli" onClick={() => navigate("/privacidad", {state})}>
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", {state})}>
          Política de Cookies
        </p>
        
      </footer>
    </div>
  );
}

export default IndexPage;
