import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "./perfil.css";

const Perfil = () => {
  const { state = { success: false, user: false } } = useLocation();
  const navigate = useNavigate();

  function handleLogout(event) {
    event.preventDefault();
    // Borra la información de sesión del usuario
    localStorage.removeItem("state");
    // Redirige a la página de inicio
    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="navbar">
        <ul>
        <li className="item5"> <Link to={-1}>Atrás</Link></li>
          <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
          <li className="item2">
            <a href="/" onClick={handleLogout}>
              Desconectarse
            </a>
          </li>
        </ul>
      </div>
      <br></br>
      <br></br> <br></br>
      <br></br>
      <header className="seccion">
        <p className="lin">
          Perfil de {state.user.usuario}
        </p>
      </header>
      <br></br>
      <br></br>
      <br></br>
      <article className="articleperfil">
        <br></br>
        <br></br>
        <br></br>
        <h4 className="datosperfil">
          <strong>Nombre: </strong> {state.user.nombre} {state.user.apellidos}
        </h4>
        <h4 className="datosperfil">
          <strong>Correo: </strong> {state.user.email}
        </h4>
        <h4 className="datosperfil">
          <strong>Teléfono: </strong> {state.user.telefono}
        </h4>
        <h4 className="datosperfil">
          <strong>Dirección: </strong> {state.user.direccion}
        </h4>
        <br></br>
        <br></br>
        <br></br>
      </article>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
      <p>
          Copyright &copy; INFORLAN. All rights reserved
        </p>
        <p>
        Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels, Barcelona
        </p>
        <p>
        936 36 52 77 • 638 28 85 48

        </p>
        <p>
        jlgurru@inforlan.es

        </p>
        <br></br>
        <p className="linkpoli" onClick={() => navigate("/privacidad", {state})}>
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", {state})}>
          Política de Cookies
        </p>
        
      </footer>
    </div>
  );
};

export default Perfil;
