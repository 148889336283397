import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import "./register.css";


const RegisterForm = () => {
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [email, setEmail] = useState('');
  const [usuario, setUsuario] = useState('');
  const [contras, setContras] = useState('');
  const [telefono, setTelefono] = useState('');
  const [direccion, setDireccion] = useState('');
  const [politicap, setPoliticap] = useState(false);


  const handleSubmit = e => {
    e.preventDefault();
  
    if (nombre === '' || apellidos === '' || email === '' || usuario === '' || contras === '' || telefono === '' || direccion === ''|| politicap === false) {
      window.alert('Por favor, rellena todos los campos obligatorios');
      return;
    }

    const userData = {
      Name: nombre,
      Last: apellidos,
      Mail: email,
      User: usuario,
      Cont: contras,
      Telef: telefono,
      Dire: direccion,
    };

    axios.post('https://inforlan-server.herokuapp.com/api/register', userData)
    .then(res => {
      console.log(res);
      window.location.replace('/');
      window.alert('Se ha registrado correctamente');
  })
    .catch(err => console.log(err));
}
const navigate = useNavigate();

  return (
    <div>
      <ul>
      <div className='navbar'>
      <li className='item5'>
        <a href ='/'>Inicio</a>
        </li>
        </div>
      </ul>
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
        <header className="seccion">
        <p className="lin">
          Registro
        </p>
      </header>
        <br/>
        <br/>
    <form onSubmit={handleSubmit}>
      <label>
      <br/>
        *Nombre:
        <br/>
        <input type="text" value={nombre} onChange={e => setNombre(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Apellidos:
        <br/>
        <input type="text" value={apellidos} onChange={e => setApellidos(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Email:
        <br/>
        <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Usuario:
        <br/>
        <input type="text" value={usuario} onChange={e => setUsuario(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Contraseña:
        <br/>
        <input type="password" value={contras} onChange={e => setContras(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Teléfono:
        <br/>
        <input type="tel" value={telefono} onChange={e => setTelefono(e.target.value)} />
      </label>
      <br/>
      <label>
      <br/>
        *Dirección:
        <br/>
        <input type="text" value={direccion} onChange={e => setDireccion(e.target.value)} />
      </label>
      <br/>
      <br/>
      <label>
      <br/>
      *Al marcar la casilla estás aceptando nuestra <a className="poli" href='./privacidad'>política de privacidad</a> 
      
      <input type="checkbox" name="empresa" value={politicap} onChange={e => setPoliticap(e.target.value)}/>
      </label>
      <button className='subm item5' type="submit" >Registrarse</button>
    </form>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
      <p>
          Copyright &copy; INFORLAN. All rights reserved
        </p>
        <p>
        Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels, Barcelona
        </p>
        <p>
        936 36 52 77 • 638 28 85 48

        </p>
        <p>
        jlgurru@inforlan.es

        </p>
        <br></br>
        <p className="linkpoli" onClick={() => navigate("/privacidad")}>
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies")}>
          Política de Cookies
        </p>
        
      </footer>
    
    </div>
  );
};

  
export default RegisterForm;

