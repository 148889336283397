
// import Pager from "../pagers/pagerscnu.js";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Buffer } from "buffer";

function CompoNuevos() {
  // const [page, setPage] = useState(1);
  const { state = { success: false } } = useLocation();

  const navigate = useNavigate();

  function handleLogout(event) {
    event.preventDefault();

    localStorage.removeItem("state");

    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [cnuevos, setCnuevos] = useState([]);

  useEffect(() => {
    fetch("https://inforlan-server.herokuapp.com/api/cnu")
      .then((x) => x.json())
      .then((y) => y.data)
      .then((allCnuevos) => setCnuevos(allCnuevos));
  }, []);

const handleDelete = id => {
  const requestInit = {
    method: 'DELETE'
  } 
  fetch('https://inforlan-server.herokuapp.com/api/cnudelete/' + id, requestInit)
.then((res) => {
  if (res.ok) {
    window.location.reload();
  } else {
    console.log("Error al eliminar");
  }
}
)
}


async function handleClick(id) {
  const url = 'https://inforlan-server.herokuapp.com/api/oportunidadescnu';

  const data = {
    id,
    ofertas: true
  };

  try {
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    console.error(error);
  }
}


  return (
    <div>
      <div className="navbar">
        <ul>
          <li className="item5">
            {" "}
            <Link to={-1}>Atrás</Link>
          </li>
          <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
          {state === null ||
          !state.success ||
          state.success === false ||
          state.user === null ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              <li className="item1">
                <p onClick={() => navigate("/perfil", { state })}>
                  Perfil de {state.user.usuario}
                </p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )}
          {state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "Joseba" ? (
            <li className="item5">
              <p onClick={() => navigate("/componuevosi", { state })}>
                Crear componente nuevo
              </p>
            </li>
          ) : null}
        </ul>
      </div>
      <br />
      <br /> <br />
      <br />
      <header className="seccion">
        <p className="lin">
          Componentes nuevos
        </p>
      </header>
      <br />

      <br />
      <div className="seccion2">
      { cnuevos === null ||  !cnuevos ? (
        <div className="loading"><p >Cargando...</p></div>
      ) : 
        cnuevos.map((cnuevo) => {
          return (
            <div key={cnuevo.id}>
              <br></br>
              <br></br>

              <div className="secciontienda" >
                <br></br>
                
                <img
                  className="imagen"
                  src={`data:image/jpeg;base64,${Buffer.from(
                    cnuevo.data,
                    "binary"
                  ).toString()}`}
                  alt={`Imagen del ordenador ${cnuevo.nombre}`}
                  onClick={() =>
                    navigate(`/detallescnu/${cnuevo.id}`, { state })
                  }
                />
                <h3
                  className="nombre"
                  onClick={() =>
                    navigate(`/detallescnu/${cnuevo.id}`, { state })
                  }
                >
                  {cnuevo.nombre}{" "}
                </h3>
                <h2
                  className="precio"
                  onClick={() =>
                    navigate(`/detallescnu/${cnuevo.id}`, { state })
                  }
                >
                  {cnuevo.precio} €
                </h2>
                <br></br>
                {state === null ||
                state.user.usuario === null ||
                !state.user ? (
                  <p></p>
                ) : state.user.usuario === "Joseba" ? (
                  <div>
                    <ul>
                    <li className="item5">
                      <p className="submi" onClick={() => handleDelete(cnuevo.id)}>Eliminar</p>
                
                      </li>
                      <li  className="item5">
                        <p className="submi" onClick={() => handleClick(cnuevo.id)}>Marcar como oferta</p>
                      </li>
                      </ul>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>
                ) : null}
              </div>

            </div>
          );
        })}
        </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
        <p>Copyright &copy; INFORLAN. All rights reserved</p>
        <p>
          Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels,
          Barcelona
        </p>
        <p>936 36 52 77 • 638 28 85 48</p>
        <p>jlgurru@inforlan.es</p>
        <br></br>
        <p
          className="linkpoli"
          onClick={() => navigate("/privacidad", { state })}
        >
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", { state })}>
          Política de Cookies
        </p>
      </footer>
    </div>
  );
}

export default CompoNuevos;

//   const [imageList, setImageList] = useState([]);
//   const [selectedImage, setSelectedImage] = useState(null);

//   useEffect(() => {
//     // Get the list of images from the server
//     axios.get('http://localhost:3001/api/images').then((response) => {
//       setImageList(response.data);

//     });
//   }, []);

//   const handleImageClick = (image) => {
//     // Get the selected image from the server
//     axios.get(`http://localhost:3001/api/images/${image.id}`).then((response) => {
//       // Convert the image to a base64 string
//       const imageData = Buffer.from(response.data.image, 'binary').toString('base64');
//       setSelectedImage(imageData);
//     });
//   }

/* <ul><button>
                    Editar
                  </button>{" "}
                  <button onClick={() => handleDelete(barrio.id)}>Eliminar</button>



        {imageList.map((image) => (
          <li key={image.id} onClick={() => handleImageClick(image)}>{image.nombre}</li>
        ))}
      </ul>
      {selectedImage && <img src={`data:image/jpeg;base64,${selectedImage}`} alt="Ordenador nuevo" />} */
