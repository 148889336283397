import React from "react";
import { Buffer } from "buffer";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import "./pago.css";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51MMSzYBtUvXZLw6b5zUD0s7RXKkWMgSR1bqlTRBgwQekBAmT6rzcpYLZ60C9cIxZHK39xPj72hRAxDN7HubeZDu400RjfffP3D"
);

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { state, state2 } = useLocation();
  // const cnuevosArray = JSON.parse(cnuevos);
  // const cnuevosA = JSON.parse(state2);
console.log(state)
   console.log(state2)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    setLoading(true);

    if (!error) {
      const { id } = paymentMethod;

      try {
        const response = await fetch("http://localhost:3001/api/pay", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            amount: state2.precio,
          }),
        });
        const data = await response.json();
        console.log(data);

        elements.getElement(CardElement).clear();
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  console.log(!stripe || loading);

  return (
    <form onSubmit={handleSubmit} className="card card-body">
      {/* <img src="../../../../public/img/tarjeta.jpg" alt="prueba" classname="img-fluid" /> */}
      <img
        className="imagen"
        src={`data:image/jpeg;base64,${Buffer.from(
          state2.data,
          "binary"
        ).toString()}`}
        alt={`Imagen del ordenador ${state2.nombre}`}
      />
      <h3 className="nombre">{state2.nombre}</h3>
      <h2 className="precio">{state2.precio} €</h2>

      <div className="form-group">
        <CardElement />
      </div>
      <div>
        <button className="compra" disabled={!stripe}>
          {loading ? <span>Cargando...</span> : "Comprar"}
        </button>
      </div>
    </form>
  );
};

function App() {
  return (
    <Elements stripe={stripePromise}>
      <div className="seccion">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <CheckoutForm />
          </div>
        </div>
      </div>
    </Elements>
  );
}

export default App;


