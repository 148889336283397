import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import axios from 'axios';
import { Buffer } from "buffer";

function CompoSmano() {
  const {state = {success: false}} = useLocation();
  const navigate = useNavigate();

  function handleLogout(event) {
    event.preventDefault();

    localStorage.removeItem("state");

    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function handleClick(id) {
    const url = 'https://inforlan-server.herokuapp.com/api/oportunidadescnu';
  
    const data = {
      id,
      ofertas: false
    };
  
    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(window.location.reload());
    } catch (error) {
      console.error(error);
    }
  }

  async function handleClick2(id) {
    const url = 'https://inforlan-server.herokuapp.com/api/oportunidadescsm';
  
    const data = {
      id,
      ofertas: false
    };
  
    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(window.location.reload());
    } catch (error) {
      console.error(error);
    }
  }

  async function handleClick3(id) {
    const url = 'https://inforlan-server.herokuapp.com/api/oportunidadesonu';
  
    const data = {
      id,
      ofertas: false
    };
  
    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(window.location.reload());
    } catch (error) {
      console.error(error);
    }
  }

  async function handleClick4(id) {
    const url = 'https://inforlan-server.herokuapp.com/api/oportunidadesosm';
  
    const data = {
      id,
      ofertas: false
    };
  
    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(window.location.reload());
    } catch (error) {
      console.error(error);
    }
  }

  async function handleClick5(id) {
    const url = 'https://inforlan-server.herokuapp.com/api/oportunidadescons';
  
    const data = {
      id,
      ofertas: false
    };
  
    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(window.location.reload());
    } catch (error) {
      console.error(error);
    }
  }


  const [csmano, setCsmano] = useState([]);

  useEffect(() => {
    fetch("https://inforlan-server.herokuapp.com/api/ofertascsm")
      .then((x) => x.json())
      .then((y) => y.data)
      .then((allCsmano) => setCsmano(allCsmano));
  }, []);


  const [cnuevos, setCnuevos] = useState([]);

  useEffect(() => {
    fetch("https://inforlan-server.herokuapp.com/api/ofertascnu")
      .then((x) => x.json())
      .then((y) => y.data)
      .then((allCnuevos) => setCnuevos(allCnuevos));
  }, []);


  const [onuevos, setOnuevos] = useState([]);

  useEffect(() => {
    fetch("https://inforlan-server.herokuapp.com/api/ofertasonu")
      .then((x) => x.json())
      .then((y) => y.data)
      .then((allOnuevos) => setOnuevos(allOnuevos));
  }, []);



  const [osmano, setOsmano] = useState([]);

  useEffect(() => {
    fetch("https://inforlan-server.herokuapp.com/api/ofertasosm")
      .then((x) => x.json())
      .then((y) => y.data)
      .then((allOsmano) => setOsmano(allOsmano));
  }, []);


  const [consumi, setConsumi] = useState([]);

  useEffect(() => {
    fetch("https://inforlan-server.herokuapp.com/api/ofertascons")
      .then((x) => x.json())
      .then((y) => y.data)
      .then((allConsumi) => setConsumi(allConsumi));
  }, []);


  return (
    <div>
      <div className="navbar">
        <ul>
          <li className="item5">
            {" "}
            <Link to={-1}>Atrás</Link>
          </li>
          <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
          {state === null || !state.success || state.success === false || state.user === null ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              <li className="item1">
                <p onClick={() => navigate("/perfil", { state })}>
                  Perfil de {state.user.usuario}
                </p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )}
        </ul>
      </div>
      <br />
      <br /> <br />
      <br /> <br />
      <header className="seccion">
        <p className="lin">
          ¡Ofertas y oportunidades!
        </p>
      </header>
      <br />
      <br />
      <div className="seccion2">
{cnuevos &&
        cnuevos.map((cnuevo) => {
          if (cnuevo.ofertas === true){
          return (
            <div key={cnuevo.id}>
              <br></br>
              <br></br>
            <div className="secciontienda" >
            <br></br><br></br>
              <img
                className="imagen"
                src={`data:image/jpeg;base64,${Buffer.from(
                  cnuevo.data,
                  "binary"
                ).toString()}`}
                alt={`Imagen del ordenador ${cnuevo.nombre}`}
                onClick={() => navigate(`/detallescnu/${cnuevo.id}`, { state })}
              />
              <h3 className="nombre" onClick={() => navigate(`/detallescnu/${cnuevo.id}`, { state })}>{cnuevo.nombre} </h3>
              <h2 className="precio" onClick={() => navigate(`/detallescnu/${cnuevo.id}`, { state })}>{cnuevo.precio} €</h2>
              
              <br></br>
              { state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "Joseba" ? (
            <div>
              <ul>
              <li  className="item5">
                        <p className="submi" onClick={() => handleClick(cnuevo.id)}>Quitar de ofertas</p>
                      </li>
                      </ul>
              </div>
          ) : null}
            </div>
            </div>
           );
          } return null;
        })}


      {csmano && 
        csmano.map((csmano) => {
            if (csmano.ofertas===true){
          return (
            <div key={csmano.id}>
              <br></br>
              <br></br>
            <div className="secciontienda">
            <br></br>
              <img
                className="imagen"
                src={`data:image/jpeg;base64,${Buffer.from(
                  csmano.data,
                  "binary"
                ).toString()}`}
                alt={`Imagen del ordenador ${csmano.nombre}`}
                onClick={() => navigate(`/detallescsm/${csmano.id}`, { state })}
              />
              <h3 className="nombre" onClick={() => navigate(`/detallescsm/${csmano.id}`, { state })}>{csmano.nombre}</h3>
              <h2 className="precio" onClick={() => navigate(`/detallescsm/${csmano.id}`, { state })}>{csmano.precio} €</h2>
              <br></br>
              { state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "Joseba" ? (
            <div>
              <ul>
              <li  className="item5">
                        <p className="submi" onClick={() => handleClick2(csmano.id)}>Quitar de ofertas</p>
                      </li>
                      </ul>
            <br></br><br></br><br></br>
            </div>
          ) : null}
            </div>
            </div>
          );
            }return null;
        })}


{onuevos &&
        onuevos.map((onuevo) => {
            if (onuevo.ofertas){
          return (
            <div key={onuevo.id}>
              <br></br>
              <br></br>
            <div className="secciontienda">
            <br></br>
              <img
                className="imagen"
                src={`data:image/jpeg;base64,${Buffer.from(
                  onuevo.data,
                  "binary"
                ).toString()}`}
                alt={`Imagen del ordenador ${onuevo.nombre}`}
                onClick={() => navigate(`/detallesonu/${onuevo.id}`, { state })}
              />
              <h3 className="nombre" onClick={() => navigate(`/detallesonu/${onuevo.id}`, { state })}>{onuevo.nombre}</h3>
              <h2 className="precio" onClick={() => navigate(`/detallesonu/${onuevo.id}`, { state })}>{onuevo.precio} €</h2>
              <br></br>
              { state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "Joseba" ? (
            <div>
              <ul>
              <li  className="item5">
                        <p className="submi" onClick={() => handleClick3(onuevo.id)}>Quitar de ofertas</p>
                      </li>
                      </ul>
            <br></br><br></br><br></br>
            </div>
          ) : null}
            </div>
            </div>
          );
        }return null;
        })}



{osmano &&
        osmano.map((osmano) => {
            if (osmano.ofertas){
          return (
            <div key={osmano.id}>
              <br></br><br></br>
            <div className="secciontienda">
              
              <br></br>
              <img
                className="imagen"
                src={`data:image/jpeg;base64,${Buffer.from(
                  osmano.data,
                  "binary"
                ).toString()}`}
                alt={`Imagen del ordenador ${osmano.nombre}`}
                onClick={() => navigate(`/detallesosm/${osmano.id}`, { state })}
              />
              
              <h3 className="nombre" onClick={() => navigate(`/detallesosm/${osmano.id}`, { state })}>{osmano.nombre}</h3>
              <h2 className="precio" onClick={() => navigate(`/detallesosm/${osmano.id}`, { state })}>{osmano.precio} €</h2>
              <br></br>
              { state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "Joseba" ? (
            <div>
              <ul>
              <li  className="item5">
                        <p className="submi" onClick={() => handleClick4(osmano.id)}>Quitar de ofertas</p>
                      </li>
                      </ul>
            <br></br><br></br><br></br>
            </div>
          ) : null}
            </div>
            </div>
          );
        }return null;
        })}


{consumi &&
        consumi.map((consum) => {
          return (
            <div key={consum.id}>
              <br></br>
              <br></br>

              <div className="secciontienda" >
                <br></br>
                
                <img
                  className="imagen"
                  src={`data:image/jpeg;base64,${Buffer.from(
                    consum.data,
                    "binary"
                  ).toString()}`}
                  alt={`Imagen del ordenador ${consum.nombre}`}
                  onClick={() =>
                    navigate(`/detallescnu/${consum.id}`, { state })
                  }
                />
                <h3
                  className="nombre"
                  onClick={() =>
                    navigate(`/detallescnu/${consum.id}`, { state })
                  }
                >
                  {consum.nombre}{" "}
                </h3>
                <h2
                  className="precio"
                  onClick={() =>
                    navigate(`/detallescnu/${consum.id}`, { state })
                  }
                >
                  {consum.precio} €
                </h2>
                <br></br>
                {state === null ||
                state.user.usuario === null ||
                !state.user ? (
                  <p></p>
                ) : state.user.usuario === "Joseba" ? (
                  <div>
                    <ul>
                    
                      <li  className="item5">
                        <p className="submi" onClick={() => handleClick5(consum.id)}>Quitar de ofertas</p>
                      </li>
                      </ul>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>
                ) : null}
              </div>

            </div>
          );
        })}
        </div>




      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
        <p>Copyright &copy; INFORLAN. All rights reserved</p>
        <p>
          Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels,
          Barcelona
        </p>
        <p>936 36 52 77 • 638 28 85 48</p>
        <p>jlgurru@inforlan.es</p>
        <br></br>
        <p
          className="linkpoli"
          onClick={() => navigate("/privacidad", { state })}
        >
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", { state })}>
          Política de Cookies
        </p>
      </footer>
    </div>
  );
}

export default CompoSmano;