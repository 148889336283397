import IndexPage from './pages/IndexPage/IndexPage'
import Register from './pages/register/register'
import Login from './pages/login/login'
import Perfil from './pages/perfil/perfil'
import Reparaciones from './pages/reparaciones/reparaciones'
import Contacto from './pages/contacto/contacto'
import Privacidad from './pages/privacidad/privacidad'
import Cookies from './pages/cookies/cookies'
import TiendaPrev from './pages/tienda/tiendaprev/tiendaprev'
import Ordenadores from './pages/tienda/ordenador/ordenadores/ordenadores'
import Componentes from './pages/tienda/componente/componentes/componentes'
import Consumibles from './pages/tienda/consumibles/consumibles'
import Consumiblesi from './pages/admin/consumiblesi/consumiblesi'
import OrdenaNuevos from './pages/tienda/ordenador/ordenanuevos/ordenanuevos'
import OrdenaNuevosi from './pages/admin/ordenanuevosi/ordenanuevosi'
import OrdenaSmano from './pages/tienda/ordenador/ordenasmano/ordenasmano'
import OrdenaSmanoi from './pages/admin/ordenasmanoi/ordenasmanoi'
import CompoNuevos from './pages/tienda/componente/componuevos/componuevos'
import CompoNuevosi from './pages/admin/componuevosi/componuevosi'
import CompoSmano from './pages/tienda/componente/composmano/composmano'
import CompoSmanoi from './pages/admin/composmanoi/composmanoi'
import Detailsosm from './pages/tienda/detalles/detallesosm/detallesosm'
import Detailsonu from './pages/tienda/detalles/detallesonu/detallesonu'
import Detailscsm from './pages/tienda/detalles/detallescsm/detallescsm'
import Detailscnu from './pages/tienda/detalles/detallescnu/detallescnu'
import Oportunidades from './pages/tienda/oportunidades/oportunidades'
import Pago from './pages/tienda/pago/pago'
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <div className="container">

      <Routes>

        <Route path="/" element={<IndexPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/perfil" element={<Perfil />} />
        <Route path="/reparaciones" element={<Reparaciones />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/privacidad" element={<Privacidad />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/tiendaprev" element={<TiendaPrev />} />
        <Route path="/ordenadores" element={<Ordenadores />} />
        <Route path="/componentes" element={<Componentes />} />
        <Route path="/consumibles" element={<Consumibles />} />
        <Route path="/consumiblesi" element={<Consumiblesi />} />
        <Route path="/ordenanuevos" element={<OrdenaNuevos />} />
        <Route path="/ordenanuevosi" element={<OrdenaNuevosi />} />
        <Route path="/ordenasmano" element={<OrdenaSmano />} />
        <Route path="/ordenasmanoi" element={<OrdenaSmanoi />} />
        <Route path="/componuevos" element={<CompoNuevos />} />
        <Route path="/componuevosi" element={<CompoNuevosi />} />
        <Route path="/composmano" element={<CompoSmano />} />
        <Route path="/composmanoi" element={<CompoSmanoi />} />
        <Route path="/detallesosm/:id" element={<Detailsosm />} />
        <Route path="/detallesonu/:id" element={<Detailsonu />} />
        <Route path="/detallescsm/:id" element={<Detailscsm />} />
        <Route path="/detallescnu/:id" element={<Detailscnu />} />
        <Route path="/oportunidades" element={<Oportunidades />} />
        <Route path="/pago/:id" element={<Pago />} />
      </Routes>

    </div>
  )
}

export default App;
