import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Secciones = require.context("../../../../../public/img", true);

function Ordenadores() {
  const  {state = { success: false }}  = useLocation();
  const navigate = useNavigate();
  
  function handleLogout(event) {
    event.preventDefault();
    // Borra la información de sesión del usuario
    localStorage.removeItem("state");
    // Redirige a la página de inicio
    navigate("/");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="navbar">
        <ul>
        <li className="item5"> <Link to={-1}>Atrás</Link></li>
        <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
          {state === null || !state.success || state.success === false ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              
              <li className="item1">
                <p onClick={() => navigate("/perfil", {state})}>Perfil de {state.user.usuario}</p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )} 
        </ul>
      </div>
      <br />
      <br /> <br />
      <br /> <br />
      

      <header className="seccion">
        <p className="lin">
          ¿De que clase?
          
        </p>
      </header>
      <br />
      <br />
      <div className="seccion">
        <br />
        <p onClick={() => navigate("/ordenanuevos", {state})} className="lin link">
          Nuevos
        </p>
        <br></br>
        <div>
          <p onClick={() => navigate("/ordenanuevos", {state})}>
            <img
              className="opaci"
              src={Secciones("./ordenanuevos.jpg")}
              alt="imagen de ordenadores nuevos"
            />
          </p>
        </div>
        <br />
      </div>
      <br />
      <br />
      <div className="seccion">
       
        <br />
        <p onClick={() => navigate("/ordenasmano", {state})} className="lin link">
          Segunda mano
        </p>
        <br></br>
        <div>
          <p onClick={() => navigate("/ordenasmano", {state})}>
            <img
              className="opaci"
              src={Secciones("./ordenasegunda.jpg")}
              alt="imagen de ordenadores de segunda mano"
            />
          </p>
        </div>

        <br />
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <footer className="section-footer">
      <p>
          Copyright &copy; INFORLAN. All rights reserved
        </p>
        <p>
        Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels, Barcelona
        </p>
        <p>
        936 36 52 77 • 638 28 85 48

        </p>
        <p>
        jlgurru@inforlan.es

        </p>
        <br></br>
        <p className="linkpoli" onClick={() => navigate("/privacidad", {state})}>
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", {state})}>
          Política de Cookies
        </p>
        
      </footer>
    </div>
  );
}

export default Ordenadores;