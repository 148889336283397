import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import "./reparaciones.css";
const Secciones = require.context("../../../public/img", true);

const Reparaciones = () => {
  const { state = { success: false, user: false } } = useLocation();
  const navigate = useNavigate();

  function handleLogout(event) {
    event.preventDefault();
    // Borra la información de sesión del usuario
    localStorage.removeItem("state");
    // Redirige a la página de inicio
    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <div className="navbar">
        <ul>
            <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>Inicio</p>
            </li>
            {state === null || !state.success || state.success === false ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              
              <li className="item1">
                <p onClick={() => navigate("/perfil", {state})}>Perfil de {state.user.usuario}</p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )} 
        </ul>
        </div>
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      <header className="seccion">
        <p className="lin">
          Reparaciones
        </p>
      </header>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <article className="seccion">
      <br></br>
      <br></br>
      <br></br>
      <img className="imagen2" src={Secciones("./reparaciones1.jpg")} alt="reparaciones" ></img>
      <br></br>
      <br></br>
      <br></br>
      </article>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
      <p>
          Copyright &copy; INFORLAN. All rights reserved
        </p>
        <p>
        Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels, Barcelona
        </p>
        <p>
        936 36 52 77 • 638 28 85 48

        </p>
        <p>
        jlgurru@inforlan.es

        </p>
        <br></br>
        <p className="linkpoli" onClick={() => navigate("/privacidad", {state})}>
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", {state})}>
          Política de Cookies
        </p>
        
      </footer>
    </div>
  );
};

export default Reparaciones;
