import { useEffect } from "react";
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import "./privacidad.css";

const Privacidad = () => {

  const  {state = { success: false }}  = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="navbar">
        <ul>
        <li className="item5"> <Link to={-1}>Atrás</Link></li>

          <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
        </ul>
      </div>
      <div>
      <br></br>
        <br></br><br></br>
        <br></br>
      <header className="seccion">
        <p className="lin">POLÍTICA DE PRIVACIDAD</p>
      </header>
      <br></br>
      <br></br>
      <br></br>
      <div className="privacidad">
        <p>
          <br></br>
          <strong>Datos del responsable del fichero</strong>
          <br></br>
          • Identidad: INFORLAN S.L. - CIF: 33960507H<br></br>
          • Dirección/C. P: Carrer de la Llibertat, 13, 08860 Castelldefels,
          Barcelona<br></br>
          • Teléfono: 936 36 52 77<br></br>
          • Email: jlgurru@inforlan.es<br></br>
          <br></br>
          <strong>Finalidades del tratamiento</strong><br></br>
          Le informamos que los datos que nos facilite por cualquier medio
          (página web, correo electrónico, formularios en papel, y/o cualquier
          otro documento) serán incorporados un fichero titularidad de Inforlan.<br></br>
          
          Inforlan tratará la información que nos proporcionan las personas
          interesadas con las siguientes finalidades: <br></br><br></br>
          • <strong>Gestionar cualquier tipo de solicitud, sugerencia o petición</strong> sobre nuestros servicios
          profesionales que nos formulen las personas interesadas.<br></br> <br></br> 
          • <strong>Comunicaciones comerciales: </strong>Tratamiento de sus datos con la finalidad
          de informarle sobre actividades, artículos de interés e información
          general sobre nuestros servicios vía correo electrónico. <br></br><br></br> 
          •<strong> Remisión de comunicaciones promocionales vía electrónica: </strong>Tratamiento de sus datos
          con la finalidad de informarle sobre nuestros servicios, ofertas,
          promociones y artículos o contenido, que puedan resultar de su
          interés, vía electrónica (e-mail, SMS, Whatsapp) y/o telefónica. Las
          personas interesadas podrán darse de baja de estas comunicaciones en
          la siguiente dirección de correo electrónico: jlgurru@inforlan.es.<br></br><br></br> 
          <strong>Legitimación</strong> <br></br>
          La base legal para el tratamiento de los datos es el
          consentimiento de las personas interesadas para la tramitación y
          gestión de cualquier solicitud de información o consulta sobre
          nuestros servicios profesionales, así como para el envío de
          comunicaciones comerciales llevadas a cabo por Inforlan. <br></br><br></br> 
          <strong>¿Cómo obtenemos sus datos?</strong> <br></br> 
          Inforlan obtiene sus datos por las siguientes
          fuentes: <br></br>
          1. La información que Usted nos facilita cuando contrata y
          mantiene productos y/o servicios con nosotros, tanto de forma directa
          como indirecta. <br></br>
          2. Fuentes de información externas, como diarios y
          boletines oficiales, registros públicos, resoluciones de las
          Administraciones Públicas, guías telefónicas, listas de personas
          pertenecientes a colegios profesionales, listas oficiales para la
          prevención del fraude, redes sociales e Internet, así como terceras
          empresas a las que Ud. haya prestado su consentimiento para la cesión
          de sus datos a entidades de crédito, financieras y de seguros.<br></br> 
          En particular, los datos que trataremos incluyen las siguientes
          categorías: datos de carácter identificativo, información comercial.<br></br> 
          <br></br>
          <strong>¿Durante cuánto tiempo conservaremos sus datos? </strong><br></br>Trataremos sus datos
          personales mientras sean necesarios para la finalidad para la cual
          fueron recabados. Si cancela todos los contratos, usted podrá:<br></br>
          1. Mantener el consentimiento para desarrollar acciones comerciales:
          trataremos sus datos para las acciones comerciales que haya
          consentido. Consideraremos que ha elegido esta opción si no revoca el
          consentimiento expresamente.<br></br> 
          2. Revocar el consentimiento para
          desarrollar acciones comerciales: cancelaremos sus datos mediante el
          bloqueo de los mismos.<br></br> 
          Con este bloqueo, Inforlan no tendrá acceso a
          sus datos y solo los tratará para su puesta a disposición a las
          Administraciones Públicas competentes, Jueces y Tribunales o el
          Ministerio Fiscal, para la atención de las posibles responsabilidades
          relacionadas con el tratamiento de los datos, en particular para el
          ejercicio y defensa de reclamaciones ante la Agencia Española de
          Protección de Datos. Conservaremos sus datos bloqueados durante los
          plazos previstos en las disposiciones aplicables o, en su caso, en las
          relaciones contractuales mantenidas con Inforlan, procediendo a la
          supresión física de sus datos una vez transcurridos dichos plazos.<br></br><br></br>
          <strong>Destinatarios</strong><br></br>
          No se ceden datos de carácter personal a terceros, salvo
          disposición legal. Tampoco se realizan transferencias internacionales
          datos a terceros países.<br></br><br></br> 
          <strong>Procedencia</strong> Los datos de carácter personal se
          obtienen directamente de las personas interesadas. Las categorías de
          datos de carácter personal que nos proporciona nuestros colaboradores
          son las siguientes: <br></br>
          • Datos de identificación. <br></br>
          • Direcciones postales o electrónicas. <br></br>
          • Datos bancarios. <br></br>
          No se tratan datos especialmente
          protegidos. <br></br><br></br>
          <strong>Derechos</strong> <strong>Derecho de Acceso, Rectificación y Supresión:</strong> Las
          personas interesadas tienen derecho a obtener confirmación sobre si en
          Inforlan estamos tratando datos personales que les conciernan, o no.
          Las personas interesadas tienen derecho a si acceder a sus datos
          personales, así como a solicitar la rectificación de los datos
          inexactos o, en su caso, solicitar su supresión cuando, entre otros
          motivos, los datos ya no sean necesarios para los fines que fueron
          recogidos.<br></br><br></br>
          <strong>Actualizaciones y modificaciones</strong>  Inforlan se reserva el
          derecho de modificar y/o actualizar la información sobre protección de
          datos cuando sea necesario para el correcto cumplimiento del
          Reglamento de Protección de Datos. Si se produjera alguna
          modificación, el nuevo texto será publicado en esta página, donde
          podrá tener acceso a la política actual. En cada caso, la relación con
          los usuarios se regirá por las normas previstas en el momento preciso
          en que se accede al web.<br></br><br></br>
          <strong>Canal de comunicación y soporte</strong> <br></br>
          Las personas interesadas podrán comunicar cualquier duda sobre el tratamiento de
          sus datos de carácter personal o interpretación de nuestra política en
          la siguiente dirección: jlgurru@inforlan.es. <br></br><br></br>
          <strong>Responsabilidad y Obligaciones del prestador</strong> <br></br>
          <u>Responsabilidades con respecto a los contenidos</u> <br></br>
          El contenido de este sitio web es de carácter general, así
          como tiene una finalidad y efectos exclusivamente informativos de
          nuestros servicios y de nuestra actividad empresarial.<br></br> 
          Inforlan se exime de cualquier responsabilidad respecto a cualquier decisión
          adoptada por el usuario del sitio web como consecuencia de la
          información contenida en ella. <br></br>
          Inforlan rechaza la responsabilidad
          sobre cualquier información no elaborada por Inforlan o no publicada
          de forma autorizada por ella bajo su nombre, al igual que la
          responsabilidad que se derive de la mala utilización de los
          contenidos, así como se reserva el derecho a actualizarlos, a
          eliminarlos, limitarlos o impedir el acceso a ellos, de manera
          temporal o definitiva. <br></br>
          <u>Responsabilidad respecto a los enlaces a otras
          páginas webs (links)</u> <br></br>
          Los enlaces introducidos en www.inforlan.com son
          de carácter meramente informativo y, por tanto, Inforlan no controla
          ni verifica ninguna información, contenido, productos o servicios
          facilitados a través de estos sitios web. En consecuencia, Inforlan
          declina cualquier tipo de responsabilidad por cualquier aspecto, en
          especial el contenido, relativo a esa página. <br></br>
          <u>Responsabilidad en el
          supuesto en que esta página sea el destino del enlace introducido en
          otra página</u> <br></br>
          Por lo que se refiere a los enlaces establecidos por otras
          páginas a este sitio, así como si cualquier usuario, entidad o página
          web deseara establecer algún tipo de enlace con destino al sitio web
          de Inforlan deberá atenerse a las siguientes estipulaciones:<br></br> 
          Deberá solicitar autorización previamente a la realización del enlace y
          deberá constar de forma expresa su otorgamiento. Sólo se podrá dirigir
          a la página de inicio.<br></br>
          El enlace debe ser absoluto y completo, es
          decir, debe llevar al usuario a la propia dirección de Inforlan debe
          abarcar completamente toda la extensión de la pantalla de la página
          inicial. En ningún caso, salvo autorización expresa y por escrito de
          Inforlan, la página que realice el enlace podrá reproducir de
          cualquier manera el sitio web de Inforlan, incluirlo como parte de su
          web o dentro de uno de sus frames o crear un browser sobre cualquiera
          de las páginas del web.<br></br>
          No se dará ningún tipo de indicación errónea o
          incorrecta acerca de la página de Inforlan. <br></br>
          Si quisiera hacer constar
          algún signo distintivo de Inforlan como marcas, logos, denominación
          deberá contar con la autorización por escrito.<br></br>
          El Titular de la página
          que ofrece el enlace deberá actuar de buena fe y no pretenderá afectar
          negativamente a la reputación o buen nombre de Inforlan.<br></br> 
          Se prohíbe, salvo autorización expresa de Inforlan, dar de alta los elementos de
          texto de la marca o del logo, el nombre de dominio o la denominación
          social de Inforlan como palabra clave para la búsqueda de sitios webs
          realizada a través de buscadores. <br></br>
          Inforlan no asume ningún tipo de
          responsabilidad por cualquier aspecto relativo a la página web que
          ofrece el enlace. El establecimiento del enlace no implica la
          existencia de algún tipo de relación, colaboración o dependencia de
          Inforlan con el titular de esa página web. <br></br>
          <u>Responsabilidad aspectos
          técnicos</u> Inforlan no garantiza la continuidad del funcionamiento del
          sitio web al igual que el mismo se encuentre en todo momento operativo
          y disponible.<br></br> Inforlan no se hace responsable de los daños y
          perjuicios directos o indirectos, incluidos daños a sistemas
          informáticos e introducción de virus existentes en la red, derivados
          de la navegación por Internet necesaria para el uso de este sitio web.<br></br><br></br>
          <strong>Obligación de los usuarios</strong><br></br>
          El usuario responderá de los daños y
          perjuicios que Inforlan pueda sufrir como consecuencia del
          incumplimiento de cualquiera de las obligaciones determinadas en esta
          nota legal. <br></br>
          Respecto a la navegación, el usuario se compromete a
          observar diligente y fielmente las recomendaciones que en su momento
          establezca Inforlan relativa al uso del sitio. A estos efectos
          Inforlan se dirigirá a los usuarios mediante cualquier medio de
          comunicación a través del sitio web. <br></br><br></br>
          <strong>Duración y Modificación</strong><br></br>
          Inforlan tendrá derecho a modificar los términos y condiciones aquí estipuladas
          de forma unilateral, total o parcialmente. Cualquier cambio aparecerá
          en la misma forma en que se encuentran en esta nota legal. <br></br>
          La vigencia
          temporal de esta nota legal coincide, por lo tanto, con el tiempo de
          su exposición, hasta el momento en que sean modificadas de forma total
          o parcial por Inforlan.<br></br> 
          Inforlan podrá dar por terminadas, suspender o
          interrumpir unilateralmente, la operatividad de este sitio web, sin
          posibilidad de solicitar indemnización alguna por parte del usuario.<br></br><br></br>
          <strong>Legislación y Jurisdicción</strong><br></br>
           Estas condiciones de uso se rigen por la
          legislación española. <br></br>
          Los usuarios y la empresa Inforlan someten todas
          las interpretaciones o conflictos que pudieran surgir derivados de
          esta nota legal a los Juzgados y Tribunales de Barcelona.
        </p>
      </div>
      </div>
    </div>
  );
};

export default Privacidad;
