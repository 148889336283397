
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import axios from 'axios';
import { Buffer } from "buffer";

function OrdenaNuevos() {
  const {
    state = {
      success: false,

    },
  } = useLocation();

  const navigate = useNavigate();

  function handleLogout(event) {
    event.preventDefault();

    localStorage.removeItem("state");

    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDelete = id => {
    const requestInit = {
      method: 'DELETE'
    } 
    fetch('https://inforlan-server.herokuapp.com/api/onudelete/' + id, requestInit)
    .then((res) => {
      if (res.ok) {
        window.location.reload();
      } else {
        console.log("Error al eliminar");
      }
    }
    )
    }


  const [onuevos, setOnuevos] = useState([]);

  useEffect(() => {
    fetch("https://inforlan-server.herokuapp.com/api/onu")
      .then((x) => x.json())
      .then((y) => y.data)
      .then((allOnuevos) => setOnuevos(allOnuevos));
  }, []);


  async function handleClick(id) {
    const url = 'https://inforlan-server.herokuapp.com/api/oportunidadesonu';
  
    const data = {
      id,
      ofertas: true
    };
   
  
    try {
      await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
        
      })     
    } catch (error) {
      console.error(error);
    }
  }

  
  return (
    <div>
      <div className="navbar">
        <ul>
          <li className="item5">
            {" "}
            <Link to={-1}>Atrás</Link>
          </li>
          <li className="item5">
            <p className="submi" onClick={() => navigate("/", { state })}>
              Inicio
            </p>
          </li>
          {state === null || !state.success || state.success === false || state.user === null ? (
            <div>
              <li className="item3">
                <a href="/register">Registrarse</a>
              </li>
              <li className="item4">
                <a href="/login">Inicia sesión</a>
              </li>
            </div>
          ) : (
            <div>
              <li className="item1">
                <p onClick={() => navigate("/perfil", { state })}>
                  Perfil de {state.user.usuario}
                </p>
              </li>
              <li className="item2">
                <a href="/" onClick={handleLogout}>
                  Desconectarse
                </a>
              </li>
            </div>
          )}
          { state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "Joseba" ? (
            <li className="item5">
              <p onClick={() => navigate("/ordenanuevosi", { state })}>
                Crear ordenador nuevo
              </p>
            </li>
          ) : null}
        </ul>
      </div>
      <br />
      <br /> <br />
      <br /> <br />
      <header className="seccion">
        <p className="lin">
          Ordenadores nuevos
        </p>
      </header>
      <br /><br />
      <div className="seccion2">
      { onuevos === null ||  !onuevos ? (
        <div className="loading"><p >Cargando...</p></div>
      ) : 
        onuevos.map((onuevo) => {
          return (
            <div key={onuevo.id}>
              <br></br>
              <br></br>
            <div className="secciontienda" >
            <br></br>
              <img
                className="imagen"
                src={`data:image/jpeg;base64,${Buffer.from(
                  onuevo.data,
                  "binary"
                ).toString()}`}
                alt={`Imagen del ordenador ${onuevo.nombre}`}
                onClick={() => navigate(`/detallesonu/${onuevo.id}`, { state })}
              />
              <h3 className="nombre" onClick={() => navigate(`/detallesonu/${onuevo.id}`, { state })}>{onuevo.nombre}</h3>
              <h2 className="precio" onClick={() => navigate(`/detallesonu/${onuevo.id}`, { state })}>{onuevo.precio} €</h2>
              
              <br></br>
              { state === null || state.user.usuario === null || !state.user ? (
            <p></p>
          ) : state.user.usuario === "Joseba" ? (
            <div>
              <ul>
                    <li className="item5">
                      <p className="submi" onClick={() => handleDelete(onuevo.id)}>Eliminar</p>
                
                      </li>
                      <li  className="item5">
                        <p className="submi" onClick={() => handleClick(onuevo.id)}>Marcar como oferta</p>
                      </li>
                      </ul>
            <br></br><br></br><br></br>
            </div>
          ) : null}
            </div>
            </div>
          );
        })}
        </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <footer className="section-footer">
        <p>Copyright &copy; INFORLAN. All rights reserved</p>
        <p>
          Carrer de la Llibertat, 13, Entresuelo, B-2, 08860 Castelldefels,
          Barcelona
        </p>
        <p>936 36 52 77 • 638 28 85 48</p>
        <p>jlgurru@inforlan.es</p>
        <br></br>
        <p
          className="linkpoli"
          onClick={() => navigate("/privacidad", { state })}
        >
          Política de Privacidad
        </p>
        <p className="linkpoli" onClick={() => navigate("/cookies", { state })}>
          Política de Cookies
        </p>
      </footer>
    </div>
  );
}

export default OrdenaNuevos;

  